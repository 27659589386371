<template>
    <div id = "apply">
        <h1 class="text1">{{$t("register.applytext.title")}}</h1>
        <h1 class="text2">{{$t("register.applytext.content")}}</h1>
        <form action="https://docs.google.com/forms/d/e/1FAIpQLSd1__q3GPOEO0IAgMjixIW53niISWtVZXDFd1llUBERWf0DPQ/viewform?usp=sf_link" method="get" target="_blank">
            <button class = "button">{{$t("register.applytext.button")}}</button>
        </form>
    </div>
</template>

<script>
export default {
   
}
</script>

<style lang="scss" scoped>
h1{
    margin-bottom: 1vh;
}

.button{
    color: white;
    background-color: $primary-color;
    border-radius: 15px !important;
    margin-top: 6%;
    font-size: 200%;
    padding-right: 2.5vw;
    padding-left: 2.5vw;
    padding-top: 1vh;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    outline:    0;
    width: 22vw;
    height: 8vh;
}

#apply{
    background-color: transparent;
    padding-top: 3%;
    padding-bottom: 20px;
    margin-bottom: 1vh;
}

.text-register{
    font-size: 20px;
    color: $secondary-color;
}

.text1 {
    margin-top: 5%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 4vh;

}

.text2 {
    margin-top: 4%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 2vh;
    width: 25vw;
    margin-left: 15%;
}


@media (max-width: 1024px) {
    .text1 {
        font-size: 150%;
    }
    .text2 {
        font-size: 100%;
        width: 90%;
        margin-left: 5%;
    }

    .button {
        font-size: 150%;
        width: 60%;
        margin-top: 5%;
    }
}

@media (max-height: 640px) {
    .button {
        width: 70%;
    }
}

@media (min-height: 850px) and (max-width: 1024px) {
    .text1 {
        font-size: 300%;
    }
    .text2 {
        font-size: 200%;
    }
    .button {
        font-size: 200%;
    }
}
</style>