<template>
  <div id="register">
      <div class="back"></div>
      <div class="big-square">      
        <Apply class="apply"/>
      </div>
      <p class="text">{{$t("register.3steps")}}</p>
      
      <div class="container">
        <div v-for="step in stepsPath" :key="step.message">
          <div class="element">
            <div class= "smaller-square">
              <p class="title">{{$t(step.title)}}</p>
              <p class="content">{{$t(step.content)}}</p>
            </div>
            <div class="circle " >{{$t(step.number)}} </div>
          </div>
        </div>
      </div>
    
    <img class="path_mobile" :src="path" />

    <div class="newsletter">
      <Newsletter class="news" />
      <img class="path" :src="path" />
    </div>
  </div>
</template>

<script>
import Apply from "@/components/heroes/ApplyHero";
import GallerySlider from "@/components/sliders/GallerySlider";
import Newsletter from "@/components/cards/Newsletter";

import {slides} from "@/assets/resources/slides"
import applyStepsPath from "@/assets/resources/apply-steps-path"

export default {
  components: {
    Apply,
    GallerySlider,
    Newsletter,
  },
    data () {
      return {
          slides,
          stepsPath: applyStepsPath,
          path: "https://i.imgur.com/IddOXmh.png",
      }
  },
};
</script>

<style lang="scss" scoped>
#register {
  background-color: white;
  padding-bottom: 10vh;
  // background-size: 100%;
}

.big-square {
  height: 40vh;
  width: 35vw;
  margin-left: 33%;
  margin-top: -30vh;
  background-color: white;
  border-radius: 2vh;
  border: 1px solid #707070;
  margin-bottom: 40px;
  z-index: 100%;
}
.card {
  border-radius: 25px;
  background-color: green;
  border: none !important;
  box-shadow: none;
}

.back {
  height: 40vh;
  width: 100%;
  background-color: $primary-color;
}

.text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 3vh;
}
.title {
  padding: 2vh;
  padding-bottom: 0vh;
  font-size: calc(14px + (40 - 14) * ((100vw - 1024px) / (2560 - 1024)));
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  padding: 5% 10%;
}

.title:nth-child(2) {
  font-size: 10px!important;
}

.content {
  font-size: calc(14px + (30 - 14) * ((100vw - 1024px) / (2560 - 1024)));
  // margin-top: -1vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  padding: 0 5% 0 5%;
  margin-bottom: 1vh;
}
.container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 7vh;
}

.element {
  margin-left: 1vw;
  position: relative;
}

.circle {
  width: 4.5vw;
  height: 4.5vw;
  border-radius: 50%;
  border: 1px solid $primary-color;
  z-index: 100%;
  background-color: white;
  font-size: calc(14px + (60 - 14) * ((100vw - 1024px) / (2560 - 1024)));
  color: $primary-color;
  position: absolute;
  top: -2.25vw;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smaller-square {
  width: 17vw;
  min-height: 15vh;
  margin-left: 2vw;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 1.5vh;
  padding-bottom: 1vh;
}

.newsletter {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.email {
  width: 50vw;
  padding-left: 15%;
}

.path {
  width: 30vw;
  object-fit: contain;
  height: auto;
  margin-left: 5vw;
}

.news {
  padding-left: 15%;
  margin-top: 1vh;
}

.path_mobile {
  visibility: hidden;
  height: 0;
}

@media (max-width: 1024px) {
  .big-square {
    width: 85%;
    margin-left: 7.5%;
    height: 30%;
    margin-top: -25vh;
  }

  .text {
    padding-left: 2.5%;
  }

  .container {
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 15%;
  }

  .element {
    height: 27vh;
    margin-left: -3%;
  }

  .smaller-square {
    width: 70%;
    margin-left: 8vw;
    height: 14vh;
  }

  .newsletter {
    flex-direction: column;
    margin-top: -2vh;
  }

  .path {
    visibility: hidden;
  }

  .path_mobile {
    visibility: visible;
    margin-top: -3vh;
    height: 40vh;
  }

  #register {
    padding-bottom: 0vh;
    margin-bottom: -15vh;
  }

  .content {
    // font-size: 110%;
    font-size: 2.6vh;
  }

  .title {
    font-size: 150%;
    padding-top: 2.5vh;
  }

  .smaller-square {
    width: 70%;
    margin-left: 7vw;
    height: 20vh;
  }

  .circle {
    width: 15vw;
    height: 15vw;
    top: -7.5vw;
    // font-size: calc(14px + (60 - 14) * ((100vw - 600px) / (900 - 640)));
    font-size: 4vh;
  }

  .news {
    padding-left: 0;
  }
}

// @media (max-height: 670px) {
//   .big-square {
//     // width: 90%;
//     // height: 90%;
//     margin-left: 5%;
//   }

//   .content {
//     font-size: 100%;
//     padding-top: -2vh;

//   }
//   .title {
//     font-size: 120%;
//     padding-top: 2vh;
//   }

// }

// @media (min-height: 850px) and (max-width: 1024px) {
//   .content {
//     font-size: 200%;
//   }

//   .title {
//     font-size: 230%;
//     padding-top: 3vh;
//   }

//   .smaller-square {
//     width: 70%;
//     margin-left: 7vw;
//     height: 19vh;
//   }

//   .circle {
//     // width: 15%;
//     // height: 15%;
//     margin-top: -22vh;
//     margin-left: 1vw;
//   }
// }
</style>
