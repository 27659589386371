let applyStepsPath = 
[
    {
        number: "register.steps.first-step.number",
        title: "register.steps.first-step.title",
        content: "register.steps.first-step.content"
    },
    {
        number: "register.steps.second-step.number",
        title: "register.steps.second-step.title",
        content: "register.steps.second-step.content"
    },
    {
        number: "register.steps.third-step.number",
        title: "register.steps.third-step.title",
        content: "register.steps.third-step.content"
    }
]

export default applyStepsPath;