<template>
  <div id="news">
    <b-loading :is-full-page="fullPage" :active.sync="sendingMessage"></b-loading>
    <div class="cadran-newsletter">
      <div class = "form-controls" v-if="!sent">
        <p class="titlu-newsletter">
          {{$t('newsletter.card.titlu-newsletter')}}  <br>
          {{$t('newsletter.card.titlu-newsletter2')}}
        </p>
        <p class="subtitlu-newsletter">
          {{$t('newsletter.card.subtitlu-newsletter')}}
        </p>
        <p class="email">{{$t('newsletter.card.your-email')}}</p>
        <input class="input-newsletter" :class="{erroroutline: errorOutline}" v-model="email_addr" type="text" name="input-n" placeholder="   ion.popescu@gmail.com">
        <a @click="enrollInNewsletter()">
          <button class="submit-newsletter">{{$t('newsletter.card.trimite')}}</button>
        </a>
        <br>
        <div class="checkbox-newsletter">
          <b-checkbox v-model="checked"> {{$t('newsletter.card.captcha-newsletter')}} </b-checkbox>
        </div>
        <h1 class = "request-message" v-if="errorCheckbox"> {{$t('newsletter.checkboxError')}} </h1>
        <h1 class = "request-message" v-if="errorEmail"> {{$t('newsletter.invalidEmail')}} </h1>
        <h1 class = "request-message" v-if="networkError"> {{$t('newsletter.networkError')}} </h1>
      </div>
      <div v-else>
        <h1 v-if="sent" class = "request-message"> {{$t('newsletter.sentMessage')}}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
      return{
        checked: false,
        sent: false,
        errorCheckbox: false,
        errorEmail: false,
        networkError: false,
        errorOutline: false,
        email_addr: '',
        api: 'https://us-central1-eestec-backend.cloudfunctions.net/app/api/eestec/newsletter',
        fullPage: true,
        sendingMessage: false
      }
    },
    methods: {
      enrollInNewsletter() {
        this.resetVariables();
        if (!this.checked) {
          this.errorCheckbox = true;
          this.errorOutline = true;
        }  else if (!this.validateEmail(this.email_addr)) { 
          this.errorEmail = true;
          this.errorOutline = true;
        } else {
          let payload = {
            email_addr: this.email_addr
          }
          this.sendingMessage = true;
          this.$http.post(this.api, payload).then(() => {
            this.sent = true;
            this.sendingMessage = false;
          },
          error => {
            // console.log(error);
            this.networkError = true;
            this.sendingMessage = false;
          })
        }
      },
      resetVariables() {
        this.sent = false;
        this.errorCheckbox = false;
        this.errorEmail = false;
        this.networkError = false;
        this.errorOutline = false;
      },
      validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

    }
}
</script>

<style lang="scss">
#news {
  .cadran-newsletter{
    background-color: transparent;
    width:35vw;
    border-radius:25px;
    margin:auto;
    margin-bottom:1%;
    margin-top: 5px;
    font-size:0.1vh;
    font-weight: bold;
    padding-bottom: 30px;
  }
  .titlu-newsletter{
    color: #000000;
    font-size:28px;
    font-weight: bold;
    padding-bottom:2%;
    text-align: left;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .subtitlu-newsletter{
    color: #000000;
    font-size:20px;
    font-weight: 400;
    text-align: left;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top: 0.5vh;
    padding-bottom: 4vh;
  }

  .email{
    font-size: 1.75vh;
    padding-bottom: 1vh;
    text-align: left;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
  }
  .input-newsletter{
    width:75%;
    height: 5vh;
    border-radius: 1vh;
    border: 1px solid #707070 !important;
    margin-bottom:5%;
    margin-left: -8.5vw;
    padding-left: 0.3vw;
    outline: 0;
    font-size: 2vh;
  }

  .erroroutline {
    border: 3px solid #707070;
  }

  .submit-newsletter{
  position:absolute;
  border-radius: 1.4vh;
  border: 1px solid white;
  margin-left: 1.5%;
  width: 9vw;
  height: 5vh;
  font-size: 1vw;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  }

  .checkbox-newsletter{
  font-size: 1.5vh;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  border-radius:25px;
  padding-top: 2vh;
  padding-right: 2vw;
  text-align: left;
  }

  input[type=checkbox] {
      transform: scale(1.7);
  }
  .b-checkbox.checkbox input[type=checkbox]:checked + .check{
    background: #707070 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:white' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") no-repeat center center !important;
    border-color: black;
  }
  .b-checkbox.checkbox input[type=checkbox]:not(:disabled) + .check  {
    border-color: #707070;
  }
  .b-checkbox.checkbox .control-label{
    color: #000000;

  }

  .request-message {
    color: $primary-color;
    font-size: 2.5vh;
    text-align: left;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  }
  
  @media (max-width: 1024px) {
    .cadran-newsletter {
      width: 100vw;
      font-size:20px;
      margin-bottom: 0;
      // margin-left: -25%;
    }

    .checkbox-newsletter {
      font-size: 1.75vh;
      padding-top: 10%;
      width: 80%;
      padding-left: 13%; 
      margin: 3vh 0;
    }

    .titlu-newsletter {
      font-size: 100%;
      text-align: center;
      width: 90%;
      padding-left: 3%;
    }

    .subtitlu-newsletter {
      text-align: center;
      margin-left: -5%;
      font-size: 90%;
    }

    .email {
      padding-left: 13%;
      font-size: 85%; 
    }

    .input-newsletter {
      width: 70%;
      height: 6vh;
      margin-left: 13%;
      font-size: 2.25vh;
    }

    .form-controls {
      display: flex;
      flex-direction: column;
    }

    .submit-newsletter{
      width: 85%;
      height: 7vh;
      font-size: 4vh;
      margin-left: -45%;
    }

    .request-message {
      font-size: 2vh;
      padding-left: 13%;
      width: 80%;
      padding-top: 1%;
      text-align: center;
    }
  }

  @media (max-width: 600px) {
    .titlu-newsletter {
      font-size: 90%;
    }
  }
  @media (min-height: 850px) and (max-width: 1024px) {
    .titlu-newsletter {
      font-size: 170%;
      width: 80%;
      padding-left: 15%;
    }

    .subtitlu-newsletter {
      font-size: 3vh;
    }

    .email {
      padding-left: 13%;
      font-size: 2.5vh; 
    }

    .submit-newsletter{
      height: 9vh;
      font-size: 5vh;
    }

    .input-newsletter {
      height: 7vh;
      font-size: 3vh;
    }

    .request-message {
      font-size: 3vh;
      padding-top: 3vh;
    }

    .checkbox-newsletter{
      font-size: 2.5vh;
    }
  }

}

</style>