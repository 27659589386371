let slides = [
    {
        image: require("@/assets/images/Register/Slide1.jpg")
    },
    {
        image: require("@/assets/images/Register/Slide2.jpg")
    },
    {
        image: require("@/assets/images/Register/Slide3.jpg")
    },
    {
        image: require("@/assets/images/Register/Slide4.jpg")
    },
    {
        image: require("@/assets/images/Register/Slide5.jpg")
    },
    {
        image: require("@/assets/images/Register/Slide6.jpg")
    },
    {
        image: require("@/assets/images/Register/Slide7.jpg")
    }
]

export {slides};