<template>
    <div id = "gallery-slider">
      <flickity ref="flickity" class="carousel-main" :options="flickityOptions">
         <div class="carousel-cell" v-for="(slide, index) in slides" :key="index">
           <img class="main" :src="slide.image">
         </div>
      </flickity>
      <flickity ref="flickity" class="carousel-nav is-hidden-mobile" :options="babyFlickity">
          <div class="carousel-cell" v-for="(slide, index) in slides" :key="index">
           <img class="baby" :src="slide.image">
         </div>
      </flickity>  
    </div>
</template>

<script>
import Flickity from 'vue-flickity'
require('flickity-as-nav-for');

export default {
  components: {
    Flickity
  },
  props : [
    'slides'
  ],
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: window.innerWidth > 480 ? false : true,
        wrapAround: true,
        autoPlay: 2000,
      },
      babyFlickity: {
        asNavFor: ".carousel-main",
        contain: true,
        pageDots: false,
        prevNextButtons: false
      }
    }
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    }
  }
}
</script>


<style lang="scss">
%rounded-borders-top {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

%rounded-borders-bottom {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

#gallery-slider {
    @extend %rounded-borders-top;

  .carousel-main .carousel-cell {
    width: 100%;
    @extend %rounded-borders-top;
  }

  .carousel-nav .carousel-cell {
    width: 20%;
    height: 25vh;
    filter: grayscale(100%);
    opacity: 0.90;
  }

  .carousel-nav {
    width: 100%;
  }

  .flickity-page-dots {
    bottom: 40px !important;
  }

  .flickity-page-dots .dot {
    background: #fff;
  }

  .carousel-main .flickity-viewport {
    height: 80vh !important;
    @extend %rounded-borders-top;
  }

  .carousel-nav .flickity-viewport {
    height: 25vh!important;
  }

  .main {
    height: 80vh;
    width: 100%;
    @extend %rounded-borders-top;
    object-fit: cover;
  }

  .baby{
    width: 100%;
    height: 25vh;
    object-fit: cover;
  }

  .carousel-nav .carousel-cell.is-nav-selected {
    filter: grayscale(0%);
    opacity: 1;
  }

  .carousel-nav .flickity-viewport {
    @extend %rounded-borders-bottom;
  }

  @media (min-width: 320px) and (max-width: 480px) { 
    & .flickity-page-dots {
      bottom: -30px !important;
    }

    .main {
      height: 30vh;
      margin-top: 90px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 0 10px;
    }

    .carousel-main .flickity-viewport {
      height: 42vh !important;
      padding: 0 10px;
    }
  }
}

</style>
